<template>
    <v-app>
        <v-navigation-drawer v-model="drawer" app>
            <v-list dense>
                <div v-if="$store.state.user.role==='Superadmin'">
                    <v-list-item :to="{ name: 'Admin'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-user-secret</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Customer'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-user</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Customer
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'Church'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-church</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Church
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group append-icon="fas fa-angle-down" no-action>
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon class="indigo--text text--darken-4" small>fas fa-th</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Master Data
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list>
                            <v-list-item :to="{ name: 'Membership'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Membership</v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="{ name: 'Bible'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Bible</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-list-group>
                </div>
                <div v-else>
                    <v-list-item :to="{ name: 'MyChurch'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-church</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Church
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyMembership'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-receipt</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Membership
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyGroup'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-layer-group</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Group
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyUser'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-users</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                User
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyWorship'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-place-of-worship</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Worship
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyCommunity'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-user-friends</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Community
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group append-icon="fas fa-angle-down" no-action>
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon class="indigo--text text--darken-4" small>fas fa-calendar-alt</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Event
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list>
                            <v-list-item :to="{ name: 'MyEventCategory'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Category</v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="{ name: 'MyEvent'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Event</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-list-group>
                    <v-list-group append-icon="fas fa-angle-down" no-action>
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon class="indigo--text text--darken-4" small>fas fa-book</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Book
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list>
                            <v-list-item :to="{ name: 'MyBookCategory'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Category</v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="{ name: 'MyBook'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Book</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-list-group>
                    <v-list-group append-icon="fas fa-angle-down" no-action>
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon class="indigo--text text--darken-4" small>fas fa-play</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Video
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list>
                            <v-list-item :to="{ name: 'MyVideoCategory'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Category</v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="{ name: 'MyVideo'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Video</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-list-group>
                    <v-list-group append-icon="fas fa-angle-down" no-action>
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon class="indigo--text text--darken-4" small>fas fa-music</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Music
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list>
                            <v-list-item :to="{ name: 'MyMusicLibrary'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Library</v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="{ name: 'MyMusic'}" @click.stop="drawer = !drawer">
                                <v-list-item-title>Music</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-list-group>
                    <v-list-item :to="{ name: 'MyArticle'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-newspaper</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Article
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyRadio'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-microphone</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Radio
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MyBanner'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-image</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Banner
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'MySupport'}" @click.stop="drawer = !drawer">
                        <v-list-item-action>
                            <v-icon class="indigo--text text--darken-4" small>fas fa-phone-volume</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                Support
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                
                <v-list-item :to="{ name: 'Change Password'}" @click.stop="drawer = !drawer">
                    <v-list-item-action>
                        <v-icon class="indigo--text text--darken-4" small>fas fa-key</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Change Password
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-icon>
                        <v-icon class="indigo--text text--darken-4" small>fas fa-sign-out-alt</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Logout
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app class="indigo darken-4 white--text">
            <v-app-bar-nav-icon class="white--text" @click.stop="drawer = !drawer" />
            <v-toolbar-title>Welcome, {{$store.state.user.name}}</v-toolbar-title>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>
        <v-footer app class="indigo darken-4 white--text text-right">
            <small>© 2020 Bible All rights reserved.</small>
        </v-footer>
    </v-app>
</template>

<script>
    export default {
        data: () => ({
            drawer: false,
        }),
        methods: {
            logout () {
                this.$store.commit('logout');
            },
        }
    }
</script>