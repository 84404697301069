<template>
    <div>
        <v-row justify="center" v-if="church !== null">
            <v-col cols="12" md="2" class="px-3 py-2">
                <v-img :src="church.logo" v-if="church.logo !== ''"></v-img>
            </v-col>
            <v-col cols="12" md="10" class="px-3 py-2">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Name</v-list-item-title>
                        <v-list-item-subtitle>{{church.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-btn small color="info" title="Edit" class="ml-3 mb-3" @click="edit()">
                    Edit
                </v-btn>
                <v-divider />
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Quota</v-list-item-title>
                        <v-list-item-subtitle>{{church.user}} / {{church.quota}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Expired</v-list-item-title>
                        <v-list-item-subtitle>{{church.expired_at}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Bill</v-list-item-title>
                        <v-list-item-subtitle>Rp. {{church.bill}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
        <Form :form="form" :getData="getData" />
    </div>
</template>
<script>
    import Form from '@/views/AdminChurch/Church/Form';
    export default {
        components: {
            Form
        },
        data() {
            return {
                church: null,
                form: {
                    show: false,
                    action: 'Add',
                    valid: false,
                    id: null,
                    name: {
                        value: '',
                        rules: [
                            v => !!v || 'Name is required',
                        ],
                    },
                    logo: {
                        value: null,
                    }
                },
            }
        },
        created: function () {
            this.getData();
        },
        methods: {
            getData() {
                this.$store.dispatch('requestAPI', {
                    name: 'MyChurch'
                }).then(async response => {
                    this.church = response.data.result;
                    this.church.quota = await this.$store.dispatch('numberPrice', this.church.quota);
                    this.church.expired_at = await this.$store.dispatch('dateFormat', this.church.expired_at);
                    this.church.bill = await this.$store.dispatch('numberPrice', this.church.bill);
                    this.church.user = await this.$store.dispatch('numberPrice', this.church.user);
                });
            },
            edit() {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'EDIT';
                this.form.id = this.church.id;
                this.form.name.value = this.church.name;
                this.form.show = true;
            },
        }
    }
</script>