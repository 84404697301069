<template>
    <div>
        <div class="subtitle-1 black--text mb-1">
            Total : {{data.total}}
        </div>
        <v-data-table 
            :headers="data.headers" 
            :items="data.items"
            :loading="data.loading"
            :items-per-page="20"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1">

            <template v-slot:[`item.action`]="{ item }">
                <div class="my-2">
                    <v-btn v-if="item.leader===null" fab x-small color="info" title="Set Leader" class="mr-2" @click="setLeader(item.id)">
                        <v-icon x-small>fas fa-user-edit</v-icon>
                    </v-btn>
                    <v-btn fab x-small color="info" title="Edit" class="mr-2" @click="edit(item.id)">
                        <v-icon x-small>fas fa-pen</v-icon>
                    </v-btn>
                    <v-btn fab x-small color="error" title="Delete" @click="remove(item.id)">
                        <v-icon x-small>fas fa-trash</v-icon>
                    </v-btn>
                </div>
            </template>

            <template v-slot:[`item.image`]="{ item }">
                <v-img width="100" :src="item.image" v-if="item.image !== ''" class="ma-2"></v-img>
            </template>

            <template v-slot:[`item.quota`]="{ item }">
                {{item.user}} / {{item.quota}}
            </template>

            <template v-slot:[`item.period`]="{ item }">
                <div v-if="item.start !== null">
                    From : {{item.start}} 
                    <br>
                    To : {{item.finish}}
                </div>
            </template>

            <template v-slot:no-data>
                No Data
            </template>
        </v-data-table>
        <div class="text-center pa-4" v-if="data.pageTotal > 1">
            <v-pagination
                v-model="data.page"
                :length="data.pageTotal"
                :total-visible="9"
                @input="getData"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['data','getData','setLeader','edit','remove']
    }
</script>