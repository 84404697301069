<template>
    <v-dialog v-model="form2.show" persistent max-width="90%">
        <v-form v-model="form2.valid" onSubmit="return false;" ref="form" @submit="form2Submit">
            <v-card>
                <v-card-title>
                    <span class="headline">Set Leader</span>
                </v-card-title>
                <v-card-text>
                    <v-autocomplete
                        v-model="form2.user_id.value"
                        :items="form2.user_id.items"
                        label="User*"
                        :loading="form2.user_id.loading"
                        item-text="name"
                        item-value="id"
                        required
                    ></v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="form2.show = false">Close</v-btn>
                    <v-btn type="submit" color="primary">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
    import Swal from 'sweetalert2';
    export default {
        props: ['id','form2','getData','getChurch'],
        methods: {
            async form2Submit() {
                if(this.$refs.form.validate()) {
                    let res;
                    let name = 'MyChurchGroupLeaderSet';
                    let params = {
                        id: this.form2.id,
                        data : {
                            user_id: this.form2.user_id.value
                        }
                    };

                    res = await this.$store.dispatch('requestAPI', {
                        name: name,
                        params: params
                    });

                    if(res !== undefined) {
                        Swal.fire({
                            icon: 'success',
                            html: res.data.message,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            onOpen: () => {
                                Swal.hideLoading();
                            }
                        }).then(() => {
                            this.form2.show = false;
                            this.getData();
                        });
                    }
                }
            }   
        }
    }
</script>