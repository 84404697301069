<template>
    <v-form onSubmit="return false;" @submit="getData(1)">
        <v-card-title>
            <v-row>
                <v-col cols="12" md="3">
                    <v-text-field v-model="queryString.name" type="text" label="Name"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-autocomplete
                        v-model="queryString.type.value"
                        :items="queryString.type.items"
                        label="Type"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                    <v-btn class="mt-4 indigo darken-4 white--text" type="submit">Filter</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
    </v-form>
</template>
<script>
    export default {
        props: ['queryString','getData']
    }
</script>