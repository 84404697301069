<template>
    <v-main>
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-12">
                        <v-form v-model="form.valid" onSubmit="return false;" @submit="submit" ref="loginForm">
                            <v-toolbar color="primary" dark flat>
                                <v-toolbar-title>Login form - Admin</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-alert class="mb-6" type="error" v-if="error.show" v-html="error.html" />
                                <div>
                                    <v-img class="mt-4 mx-auto" :src="require('@/assets/logo.png')" width="150" aspect-ratio="2.18"></v-img>
                                </div>
                                <v-text-field label="Email" prepend-icon="fas fa-user" type="text" v-model="form.email.value" :rules="form.email.rules" />
                                <v-text-field label="Password" prepend-icon="fas fa-lock" type="password" v-model="form.password.value" :rules="form.password.rules" />
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn type="submit" color="primary" :loading="form.submit.loading" :disabled="form.submit.disabled">Login</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
    export default {
        name: 'Login',
        data () {
            return {
                error: {
                    show: this.$store.state.errorLogin.show,
                    html: this.$store.state.errorLogin.html
                },
                form: {
                    valid: false,
                    email: {
                        value: '',
                        rules: [
                            v => !!v || 'Email is required',
                            v => /.+@.+/.test(v) || 'Email is not valid',
                        ],
                    },
                    password: {
                        value: '',
                        rules: [
                            v => !!v || 'Password is required'
                        ],
                    },
                    submit: {
                        loading: false,
                        disabled: false
                    }
                }
            }
        },
        methods: {
            async submit() {
                if(this.$refs.loginForm.validate()) {
                    this.form.submit.loading = true;
                    this.form.submit.disabled = true;
                    this.$store.dispatch('requestAPI', {
                        name: 'AdminLogin',
                        params: {
                            data: {
                                email: this.form.email.value,
                                password: this.form.password.value
                            }
                        }
                    }).then(response => {
                        this.form.submit.loading = false;
                        this.form.submit.disabled = false;
                        if(response !== undefined) {
                            this.$store.commit('login', response.data.results);
                        }
                    });
                }
            },    
        },
    }
</script>