<template>
    <v-dialog v-model="form.show" persistent max-width="90%">
        <v-form v-model="form.valid" onSubmit="return false;" ref="form" @submit="formSubmit">
            <v-card>
                <v-card-title>
                    <span class="headline">{{form.action}} Church</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field 
                        label="Name*"
                        v-model="form.name.value"
                        :rules="form.name.rules"
                        required
                    />
                    <v-file-input 
                        label="Logo"
                        v-model="form.logo.value" 
                        accept="image/*"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="form.show = false">Close</v-btn>
                    <v-btn type="submit" color="primary">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
    import Swal from 'sweetalert2';
    export default {
        props: ['form','getData'],
        methods: {
            async formSubmit() {
                if(this.$refs.form.validate()) {
                    let res;
                    let name = 'MyChurchUpdate';
                    let params = {
                        data : {
                            name: this.form.name.value,
                            logo: this.form.logo.value
                        }
                    };

                    res = await this.$store.dispatch('requestAPI', {
                        name: name,
                        params: params
                    });

                    if(res !== undefined) {
                        Swal.fire({
                            icon: 'success',
                            html: res.data.message,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            onOpen: () => {
                                Swal.hideLoading();
                            }
                        }).then(() => {
                            this.form.show = false;
                            this.getData();
                        });
                    }
                }
            }   
        }
    }
</script>