<template>
    <div>
        <div class="subtitle-1 black--text mb-1">
            Total : {{data.total}}
        </div>
        <v-data-table 
            :headers="data.headers" 
            :items="data.items"
            :loading="data.loading"
            :items-per-page="20"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1">

            <template v-slot:[`item.photo`]="{ item }">
                <v-avatar class="ma-2">
                    <img :src="item.photo" v-if="item.photo !== ''">
                    <v-icon v-else>fas fa-user</v-icon>
                </v-avatar>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <div class="my-2">
                    <v-btn fab x-small color="info" title="Edit Position" class="mr-2" @click="changePosition(item.id)">
                        <v-icon x-small>fas fa-user-edit</v-icon>
                    </v-btn>
                </div>
            </template>

            <template v-slot:no-data>
                No Data
            </template>
        </v-data-table>
        <div class="text-center pa-4" v-if="data.pageTotal > 1">
            <v-pagination
                v-model="data.page"
                :length="data.pageTotal"
                :total-visible="9"
                @input="getData"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['data','getData','changePosition']
    }
</script>