<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="6" class="px-3 py-2">
                
            </v-col>
            <v-col cols="12" md="6" class="px-3 py-2 text-right">
                
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-card>
            <FormFilter :queryString="queryString" :getData="getData" />
            <v-card-text>
                <Datatable :data="data" :getData="getData" />
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import FormFilter from '@/views/Church/User/FormFilter';
    import Datatable from '@/views/Church/User/Datatable';
    export default {
        components: {
            FormFilter,
            Datatable
        },
        props: ['id'],
        data() {
            return {
                queryString: {
                    page: 1,
                    name: '',
                    email: '',
                    phone: '',
                    church_id: this.id
                },
                data: {
                    total: 0,
                    headers: [
                        { text: '#', value: 'number' },
                        { text: 'Photo', value: 'photo' },
                        { text: 'Name', value: 'name' },
                        { text: 'Email', value: 'email' },
                        { text: 'Phone', value: 'phone' },
                        { text: 'Score', value: 'score' }
                    ],
                    items: [],
                    loading: false
                },
            }
        },
        created: function () {
            this.getData();
            this.getChurch();
        },
        methods: {
            getChurch() {
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchDetail',
                    params: {
                        id: this.id
                    }
                }).then(async response => {
                    let church = response.data.result;
                    church.quota = await this.$store.dispatch('numberPrice', church.quota);
                    church.expired_at = await this.$store.dispatch('dateFormat', church.expired_at);
                    church.bill = await this.$store.dispatch('numberPrice', church.bill);
                    this.$emit("updateData", church);
                });
            },
            getData(page) {
                if(page !== undefined) {
                    this.queryString.page = page;
                }

                this.data.items = [];
                this.data.loading = true;
                this.data.pageTotal = 0;
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchUserLists',
                    params: {
                        church_id: this.id,
                        queryString: this.queryString
                    }
                }).then(async response => {
                    this.data.page = parseInt(response.data.result.page);
                    this.data.pageTotal = response.data.result.pageTotal;
                    this.data.total = response.data.result.total;
                    this.data.items = response.data.result.data;
                    this.data.loading = false;

                    let number = response.data.result.limit * (response.data.result.page - 1);
                    for (const value of this.data.items) {
                        number = number + 1;
                        value.number = number;
                        value.score = await this.$store.dispatch('numberPrice', value.score);
                    }
                });
            },
        }
    }
</script>