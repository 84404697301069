import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '@/router';
import Swal from 'sweetalert2';
import moment from 'moment';

axios.defaults.baseURL = process.env.VUE_APP_BASEURL_API;

Vue.use(Vuex)

const localStorage_user = 'BibleDashboard_User';
const listLimit = 20;

export default new Vuex.Store({
    state: {
        baseURL: process.env.VUE_APP_BASEURL,
        user : JSON.parse(localStorage.getItem(localStorage_user)) || '',
        errorLogin: {
            show: false,
            html: ''
        }
    },
    mutations: {
        login(state, user) {
            state.user = user;
            localStorage.setItem(localStorage_user, JSON.stringify(user));
            if(user.role === 'Superadmin') {
                router.replace({
                    name: 'Admin'
                });
            } else {
                router.replace({
                    name: 'MyChurch'
                });
            }
        },
        logout(state) {
            state.user = '';
            localStorage.removeItem(localStorage_user);
            router.replace({
                name: 'Login'
            });
        },
    },
    actions: {
        loading() {
            Swal.fire({
                title: 'Now loading',
                text: 'Please Wait',
                allowEscapeKey: false,
                allowOutsideClick: false,
                onOpen: () => {
                    Swal.showLoading();
                }
            });
        },
        loadingClose() {
            Swal.close();
        },
        requestError({commit}, error) {
            let htmlError = '';
            if (error.response !== undefined) {
                if (error.response.data.errors !== undefined && error.response.data.errors.length > 0) {
                    if (error.response.data.errors[0] === 'Token is Required.' || error.response.data.errors[0] === 'Token is Invalid.' || error.response.data.errors[0] === 'Token is Expired.') {
                        Swal.close();
                        commit('logout');
                    } else {
                        htmlError = '';
                        if(error.response.data.errors.length > 1)  {
                            htmlError = '<ul style="color:#ff5252;">';
                            for (const value of error.response.data.errors) {
                                htmlError += '<li>'+value+'</li>';
                            }
                            htmlError += '</ul>';
                        } else {
                            htmlError = error.response.data.errors[0];
                        }
                    }
                }
            } else {
                htmlError = error.message;
            }

            if(htmlError !== '') {
                Swal.fire({
                    icon: 'error',
                    html: '<span style="color:#ff5252;">' + htmlError + '</span>',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: () => {
                        Swal.hideLoading();
                    }
                });
            }
        },
        requestSuccess(_context, message) {
            Swal.fire({
                icon: 'success',
                html: '<span style="color:#4caf50;">' + message + '</span>',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: () => {
                    Swal.hideLoading();
                }
            }).then(() => {
                Swal.close();
            });
        },
        numberPrice(_context, value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        dateFormat(_context, value) {
            return moment(value).format('DD MMMM YYYY');
        },
        async requestAPI(context, {name,params}) {
            let method = 'GET';
            let url = '';
            let queryString = '';
            let attach = false;
            switch(name) {
                //Admin
                case 'AdminLogin':
                    method = 'POST';
                    url = 'admin/login';
                    break;
                case 'AdminChangePassword':
                    method = 'POST';
                    url = 'admin/changePassword';
                    break;
                case 'AdminLists':
                    queryString += '&role='+params.queryString.role;
                    if(params.queryString.church_id !== undefined && params.queryString.church_id !== '') {
                        queryString += '&church_id='+params.queryString.church_id;
                    }
                    if(params.queryString.name !== undefined && params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.email !== undefined && params.queryString.email !== '') {
                        queryString += '&email='+params.queryString.email;
                    }
                    method = 'GET';
                    url = 'admin/lists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'AdminDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/detail/'+params.id;
                    break;
                case 'AdminCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/create';
                    break;
                case 'AdminUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/update/'+params.id;
                    break;
                case 'AdminReset':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/reset/'+params.id;
                    break;
                case 'AdminDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/delete/'+params.id;
                    break;

                //Customer
                case 'CustomerAll':
                    method = 'GET';
                    url = 'admin/customer/all';
                    break;
                case 'CustomerLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.email !== '') {
                        queryString += '&email='+params.queryString.email;
                    }
                    method = 'GET';
                    url = 'admin/customer/lists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'CustomerDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/customer/detail/'+params.id;
                    break;
                case 'CustomerCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/customer/create';
                    break;
                case 'CustomerUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/customer/update/'+params.id;
                    break;
                case 'CustomerDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/customer/delete/'+params.id;
                    break;

                //Church
                case 'ChurchLists':
                    if(params.queryString.customer.value !== 'All') {
                        queryString += '&customer_id='+params.queryString.customer.value;
                    }
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/church/lists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'ChurchDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/church/detail/'+params.id;
                    break;
                case 'ChurchCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/church/create';
                    attach = true;
                    break;
                case 'ChurchUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/church/update/'+params.id;
                    attach = true;
                    break;
                case 'ChurchDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/church/delete/'+params.id;
                    break;
                case 'ChurchMembershipLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/church/membershipLists/'+params.id+'?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'ChurchMembershipCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/church/membershipCreate/'+params.id;
                    break;
                case 'ChurchGroupLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/church/groupLists/'+params.church_id+'?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'ChurchGroupDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/church/groupDetail/'+params.church_id+'/'+params.id;
                    break;
                case 'ChurchGroupCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/church/groupCreate/'+params.church_id;
                    attach = true;
                    break;
                case 'ChurchGroupUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/church/groupUpdate/'+params.church_id+'/'+params.id;
                    attach = true;
                    break;
                case 'ChurchGroupDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/church/groupDelete/'+params.church_id+'/'+params.id;
                    break;
                case 'ChurchGroupLeaderSet':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/church/groupLeaderSet/'+params.church_id+'/'+params.id;
                    break;
                case 'ChurchUserAll':
                    if(params.queryString.group_id !== '') {
                        queryString += '&group_id='+params.queryString.group_id;
                    }
                    if(params.queryString.forLeader !== '') {
                        queryString += '&forLeader='+params.queryString.forLeader;
                    }
                    method = 'GET';
                    url = 'admin/church/userAll/'+params.church_id+'?limit='+listLimit+queryString;
                    break;
                case 'ChurchUserLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.email !== '') {
                        queryString += '&email='+params.queryString.email;
                    }
                    if(params.queryString.phone !== '') {
                        queryString += '&phone='+params.queryString.phone;
                    }
                    method = 'GET';
                    url = 'admin/church/userLists/'+params.church_id+'?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;

                //Membership
                case 'MembershipAll':
                    method = 'GET';
                    url = 'admin/membership/all';
                    break;
                case 'MembershipLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.type.value !== 'All') {
                        queryString += '&type='+params.queryString.type.value;
                    }
                    method = 'GET';
                    url = 'admin/membership/lists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MembershipDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/membership/detail/'+params.id;
                    break;
                case 'MembershipCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/membership/create';
                    break;
                case 'MembershipUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/membership/update/'+params.id;
                    break;
                case 'MembershipDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/membership/delete/'+params.id;
                    break;
                
                //User
                case 'UserAll':
                    method = 'GET';
                    url = 'admin/user/all/'+params.church_id;
                    break;

                //Bible
                case 'bibleLists':
                    method = 'GET';
                    url = 'admin/bible/lists?limit='+listLimit+'&page='+params.queryString.page+'&category='+params.queryString.category+'&pasal='+params.queryString.pasal+'&ayat='+params.queryString.ayat;
                    break;
                case 'bibleDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/bible/detail/'+params.id;
                    break;
                case 'bibleCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/bible/create';
                    break;
                case 'bibleUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/bible/update/'+params.id;
                    break;
                case 'bibleDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/bible/delete/'+params.id;
                    break;

                //Admin Church
                //Church
                case 'MyChurch':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch';
                    break;
                case 'MyChurchUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/update';
                    attach = true;
                    break;
                case 'MyChurchGroupAll':
                    method = 'GET';
                    url = 'admin/myChurch/groupAll';
                    break;
                case 'MyChurchGroupLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/groupLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchGroupDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/groupDetail/'+params.id;
                    break;
                case 'MyChurchGroupCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/groupCreate';
                    attach = true;
                    break;
                case 'MyChurchGroupUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/groupUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchGroupDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/groupDelete/'+params.id;
                    break;
                case 'MyChurchGroupLeaderSet':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/groupLeaderSet/'+params.id;
                    break;
                case 'MyChurchUserAll':
                    if(params.queryString.group_id !== '') {
                        queryString += '&group_id='+params.queryString.group_id;
                    }
                    if(params.queryString.forLeader !== '') {
                        queryString += '&forLeader='+params.queryString.forLeader;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/userAll?limit='+listLimit+queryString;
                    break;
                case 'MyChurchUserLists':
                    if(params.queryString.group.value !== '') {
                        queryString += '&group_id='+params.queryString.group.value;
                    }    
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.email !== '') {
                        queryString += '&email='+params.queryString.email;
                    }
                    if(params.queryString.phone !== '') {
                        queryString += '&phone='+params.queryString.phone;
                    }
                    if(params.queryString.position.value !== 'All') {
                        queryString += '&position='+params.queryString.position.value;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/userLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchUserDetail':
                    method = 'GET';
                    url = 'admin/myChurch/userDetail/'+params.id;
                    break;
                case 'MyChurchUserChangePosition':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/userChangePosition/'+params.id;
                    break;
                case 'MyChurchMembershipLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.type.value !== 'All') {
                        queryString += '&type='+params.queryString.type.value;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/membershipLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchWorshipLists':
                    if(params.queryString.title !== '') {
                        queryString += '&title='+params.queryString.title;
                    }
                    if(params.queryString.speaker !== '') {
                        queryString += '&speaker='+params.queryString.speaker;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/worshipLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchWorshipDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/worshipDetail/'+params.id;
                    break;
                case 'MyChurchWorshipCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/worshipCreate';
                    attach = true;
                    break;
                case 'MyChurchWorshipUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/worshipUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchWorshipDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/worshipDelete/'+params.id;
                    break;
                case 'MyChurchCommunityLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.leader_name !== '') {
                        queryString += '&leader_name='+params.queryString.leader_name;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/communityLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchCommunityDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/communityDetail/'+params.id;
                    break;
                case 'MyChurchCommunityCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/communityCreate';
                    break;
                case 'MyChurchCommunityUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/communityUpdate/'+params.id;
                    break;
                case 'MyChurchCommunityDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/communityDelete/'+params.id;
                    break;
                case 'MyChurchEventCategoryAll':
                    method = 'GET';
                    url = 'admin/myChurch/eventCategoryAll';
                    break;
                case 'MyChurchEventCategoryLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/eventCategoryLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchEventCategoryDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/eventCategoryDetail/'+params.id;
                    break;
                case 'MyChurchEventCategoryCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/eventCategoryCreate';
                    break;
                case 'MyChurchEventCategoryUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/eventCategoryUpdate/'+params.id;
                    break;
                case 'MyChurchEventCategoryDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/eventCategoryDelete/'+params.id;
                    break;
                case 'MyChurchBookCategoryAll':
                    method = 'GET';
                    url = 'admin/myChurch/bookCategoryAll';
                    break;
                case 'MyChurchBookCategoryLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/bookCategoryLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchBookCategoryDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/bookCategoryDetail/'+params.id;
                    break;
                case 'MyChurchBookCategoryCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/bookCategoryCreate';
                    break;
                case 'MyChurchBookCategoryUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/bookCategoryUpdate/'+params.id;
                    break;
                case 'MyChurchBookCategoryDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/bookCategoryDelete/'+params.id;
                    break;
                case 'MyChurchBookLists':
                    if(params.queryString.category_id.value !== '') {
                        queryString += '&category_id='+params.queryString.category_id.value;
                    }
                    if(params.queryString.title !== '') {
                        queryString += '&title='+params.queryString.title;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/bookLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchBookDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/bookDetail/'+params.id;
                    break;
                case 'MyChurchBookCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/bookCreate';
                    attach = true;
                    break;
                case 'MyChurchBookUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/bookUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchBookDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/bookDelete/'+params.id;
                    break;
                case 'MyChurchEventLists':
                    if(params.queryString.category_id.value !== '') {
                        queryString += '&category_id='+params.queryString.category_id.value;
                    }
                    if(params.queryString.title !== '') {
                        queryString += '&title='+params.queryString.title;
                    }
                    if(params.queryString.speaker !== '') {
                        queryString += '&speaker='+params.queryString.speaker;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/eventLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchEventDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/eventDetail/'+params.id;
                    break;
                case 'MyChurchEventCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/eventCreate';
                    attach = true;
                    break;
                case 'MyChurchEventUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/eventUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchEventDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/eventDelete/'+params.id;
                    break;
                case 'MyChurchVideoCategoryAll':
                    method = 'GET';
                    url = 'admin/myChurch/videoCategoryAll';
                    break;
                case 'MyChurchVideoCategoryLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/videoCategoryLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchVideoCategoryDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/videoCategoryDetail/'+params.id;
                    break;
                case 'MyChurchVideoCategoryCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/videoCategoryCreate';
                    break;
                case 'MyChurchVideoCategoryUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/videoCategoryUpdate/'+params.id;
                    break;
                case 'MyChurchVideoCategoryDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/videoCategoryDelete/'+params.id;
                    break;
                case 'MyChurchVideoLists':
                    if(params.queryString.category_id.value !== '') {
                        queryString += '&category_id='+params.queryString.category_id.value;
                    }
                    if(params.queryString.title !== '') {
                        queryString += '&title='+params.queryString.title;
                    }
                    if(params.queryString.speaker !== '') {
                        queryString += '&speaker='+params.queryString.speaker;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/videoLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchVideoDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/videoDetail/'+params.id;
                    break;
                case 'MyChurchVideoCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/videoCreate';
                    attach = true;
                    break;
                case 'MyChurchVideoUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/videoUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchVideoDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/videoDelete/'+params.id;
                    break;
                case 'MyChurchMusicLibraryAll':
                    method = 'GET';
                    url = 'admin/myChurch/musicLibraryAll';
                    break;
                case 'MyChurchMusicLibraryLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/musicLibraryLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchMusicLibraryDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/musicLibraryDetail/'+params.id;
                    break;
                case 'MyChurchMusicLibraryCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/musicLibraryCreate';
                    attach = true;
                    break;
                case 'MyChurchMusicLibraryUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/musicLibraryUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchMusicLibraryDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/musicLibraryDelete/'+params.id;
                    break;
                case 'MyChurchMusicLists':
                    if(params.queryString.library_id.value !== '') {
                        queryString += '&library_id='+params.queryString.library_id.value;
                    }
                    if(params.queryString.title !== '') {
                        queryString += '&title='+params.queryString.title;
                    }
                    if(params.queryString.artist !== '') {
                        queryString += '&artist='+params.queryString.artist;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/musicLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchMusicDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/musicDetail/'+params.id;
                    break;
                case 'MyChurchMusicCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/musicCreate';
                    attach = true;
                    break;
                case 'MyChurchMusicUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/musicUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchMusicDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/musicDelete/'+params.id;
                    break;
                case 'MyChurchArticleLists':
                    if(params.queryString.title !== '') {
                        queryString += '&title='+params.queryString.title;
                    }
                    if(params.queryString.author !== '') {
                        queryString += '&author='+params.queryString.author;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/articleLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchArticleDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/articleDetail/'+params.id;
                    break;
                case 'MyChurchArticleCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/articleCreate';
                    attach = true;
                    break;
                case 'MyChurchArticleUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/articleUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchArticleDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/articleDelete/'+params.id;
                    break;
                case 'MyChurchRadioLists':
                    if(params.queryString.name !== '') {
                        queryString += '&name='+params.queryString.name;
                    }
                    if(params.queryString.location !== '') {
                        queryString += '&location='+params.queryString.location;
                    }
                    method = 'GET';
                    url = 'admin/myChurch/radioLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchRadioDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/radioDetail/'+params.id;
                    break;
                case 'MyChurchRadioCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/radioCreate';
                    break;
                case 'MyChurchRadioUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/radioUpdate/'+params.id;
                    break;
                case 'MyChurchRadioDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/radioDelete/'+params.id;
                    break;
                case 'MyChurchBannerLists':
                    method = 'GET';
                    url = 'admin/myChurch/bannerLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchBannerDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/bannerDetail/'+params.id;
                    break;
                case 'MyChurchBannerCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/bannerCreate';
                    attach = true;
                    break;
                case 'MyChurchBannerUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/bannerUpdate/'+params.id;
                    attach = true;
                    break;
                case 'MyChurchBannerDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/bannerDelete/'+params.id;
                    break;
                case 'MyChurchSupportLists':
                    method = 'GET';
                    url = 'admin/myChurch/supportLists?limit='+listLimit+'&page='+params.queryString.page+queryString;
                    break;
                case 'MyChurchSupportDetail':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/supportDetail/'+params.id;
                    break;
                case 'MyChurchSupportCreate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/supportCreate';
                    break;
                case 'MyChurchSupportUpdate':
                    context.dispatch('loading');
                    method = 'POST';
                    url = 'admin/myChurch/supportUpdate/'+params.id;
                    break;
                case 'MyChurchSupportDelete':
                    context.dispatch('loading');
                    method = 'GET';
                    url = 'admin/myChurch/supportDelete/'+params.id;
                    break;
        
                default:
                    url = '';
            }
            try {
                let res = await context.dispatch('requestAPIProcess', {
                    method: method,
                    url: url,
                    params: params,
                    attach: attach
                });
                return res;
            } catch (err) {
                context.dispatch('requestError', err);
            }
        },
        async requestAPIProcess(context, {method, url, params, attach}) {
            let res;
                if(attach === true) {
                    let formData = new FormData();
                    if(url === 'admin/church/create' || url === 'admin/church/update/'+params.id) {
                        formData.append('customer_id', params.data.customer_id);
                        formData.append('name', params.data.name);
                        if(params.data.logo !== null) {
                            formData.append('logo', params.data.logo);
                        }
                    } else if(url === 'admin/church/groupCreate/'+params.church_id || url === 'admin/church/groupUpdate/'+params.church_id+'/'+params.id) {
                        formData.append('name', params.data.name);
                        formData.append('description', params.data.description);
                        formData.append('quota', params.data.quota);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/update') {
                        formData.append('name', params.data.name);
                        if(params.data.logo !== null) {
                            formData.append('logo', params.data.logo);
                        }
                    } else if(url === 'admin/myChurch/groupCreate' || url === 'admin/myChurch/groupUpdate/'+params.id) {
                        formData.append('name', params.data.name);
                        formData.append('description', params.data.description);
                        formData.append('quota', params.data.quota);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/worshipCreate' || url === 'admin/myChurch/worshipUpdate/'+params.id) {
                        formData.append('title', params.data.title);
                        formData.append('description', params.data.description);
                        formData.append('speaker', params.data.speaker);
                        formData.append('date', params.data.date);
                        formData.append('time', params.data.time);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/eventCreate' || url === 'admin/myChurch/eventUpdate/'+params.id) {
                        formData.append('category_id', params.data.category_id);
                        formData.append('title', params.data.title);
                        formData.append('description', params.data.description);
                        formData.append('location', params.data.location);
                        formData.append('contact', params.data.contact);
                        formData.append('speaker', params.data.speaker);
                        formData.append('date', params.data.date);
                        formData.append('time', params.data.time);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/bookCreate' || url === 'admin/myChurch/bookUpdate/'+params.id) {
                        formData.append('category_id', params.data.category_id);
                        formData.append('title', params.data.title);
                        formData.append('description', params.data.description);
                        formData.append('recommended', params.data.recommended);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                        if(params.data.file !== null) {
                            formData.append('file', params.data.file);
                        }
                    } else if(url === 'admin/myChurch/videoCreate' || url === 'admin/myChurch/videoUpdate/'+params.id) {
                        formData.append('category_id', params.data.category_id);
                        formData.append('title', params.data.title);
                        formData.append('description', params.data.description);
                        formData.append('speaker', params.data.speaker);
                        formData.append('url', params.data.url);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/musicLibraryCreate' || url === 'admin/myChurch/musicLibraryUpdate/'+params.id) {
                        formData.append('name', params.data.name);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/musicCreate' || url === 'admin/myChurch/musicUpdate/'+params.id) {
                        formData.append('library_id', params.data.library_id);
                        formData.append('title', params.data.title);
                        formData.append('artist', params.data.artist);
                        formData.append('recommended', params.data.recommended);
                        formData.append('inspiration', params.data.inspiration);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                        if(params.data.file !== null) {
                            formData.append('file', params.data.file);
                        }
                    } else if(url === 'admin/myChurch/articleCreate' || url === 'admin/myChurch/articleUpdate/'+params.id) {
                        formData.append('title', params.data.title);
                        formData.append('body', params.data.body);
                        formData.append('author', params.data.author);
                        formData.append('date', params.data.date);
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    } else if(url === 'admin/myChurch/bannerCreate' || url === 'admin/myChurch/bannerUpdate/'+params.id) {
                        if(params.data.image !== null) {
                            formData.append('image', params.data.image);
                        }
                    }
                    
                    res = await axios({
                        method: 'POST',
                        url: url,
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            token: context.state.user.token
                        }
                    });
                } else {
                    if(method === 'POST' || method === 'PUT') {
                        res = await axios({
                            method: method,
                            url: url,
                            data: params.data,
                            headers: {
                                token: context.state.user.token
                            }
                        });
                    } else {
                        res = await axios({
                            method: method,
                            url: url,
                            headers: {
                                token: context.state.user.token
                            }
                        });
                    }
                }
        
            context.dispatch('loadingClose');
            return res;
        },
    },
    modules: {
        
    }
})
