<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="6" class="px-3 py-2">
                
            </v-col>
            <v-col cols="12" md="6" class="px-3 py-2 text-right">
                
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-card>
            <FormFilter :queryString="queryString" :getData="getData" />
            <v-card-text>
                <Datatable :data="data" :getData="getData" :changePosition="changePosition" />
            </v-card-text>
        </v-card>
        <Form :form="form" :getData="getData" :changePosition="changePosition" />
    </div>
</template>
<script>
    import FormFilter from '@/views/AdminChurch/User/FormFilter';
    import Datatable from '@/views/AdminChurch/User/Datatable';
    import Form from '@/views/AdminChurch/User/Form';
    export default {
        components: {
            FormFilter,
            Datatable,
            Form
        },
        data() {
            return {
                queryString: {
                    page: 1,
                    group: {
                        value: '',
                        items: [],
                        loading: true
                    },
                    name: '',
                    email: '',
                    phone: '',
                    position: {
                        value: 'All',
                        items: [
                            {
                                id: 'All',
                                name: 'All'
                            },
                            {
                                id: 0,
                                name: 'Jemaat'
                            },
                            {
                                id: 1,
                                name: 'Pendoa'
                            },
                            {
                                id: 2,
                                name: 'Pendeta'
                            }
                        ],
                    }
                },
                data: {
                    total: 0,
                    headers: [
                        { text: '#', value: 'number' },
                        { text: 'Photo', value: 'photo' },
                        { text: 'Group', value: 'group_name' },
                        { text: 'Name', value: 'name' },
                        { text: 'Email', value: 'email' },
                        { text: 'Phone', value: 'phone' },
                        { text: 'Positon', value: 'position' },
                        { text: 'Score', value: 'score' },
                        { text: 'Action', value: 'action' }
                    ],
                    items: [],
                    loading: false
                },
                form: {
                    show: false,
                    valid: false,
                    id: null,
                    position: {
                        value: '',
                        items: [
                            {
                                id: 0,
                                name: 'Jemaat'
                            },
                            {
                                id: 1,
                                name: 'Pendoa'
                            },
                            {
                                id: 2,
                                name: 'Pendeta'
                            }
                        ]
                    }
                }
            }
        },
        created: function () {
            this.$store.dispatch('requestAPI', {
                name: 'MyChurchGroupAll'
            }).then(async response => {
                this.queryString.group.items = response.data.result;
                this.queryString.group.loading = false;
            });
            if(this.$route.query.group_id !== undefined && this.$route.query.group_id !== '') {
                this.queryString.group.value = this.$route.query.group_id;
            }
            this.getData();
        },
        methods: {
            getData(page) {
                if(page !== undefined) {
                    this.queryString.page = page;
                }

                this.data.items = [];
                this.data.loading = true;
                this.data.pageTotal = 0;
                this.$store.dispatch('requestAPI', {
                    name: 'MyChurchUserLists',
                    params: {
                        queryString: this.queryString
                    }
                }).then(async response => {
                    this.data.page = parseInt(response.data.result.page);
                    this.data.pageTotal = response.data.result.pageTotal;
                    this.data.total = response.data.result.total;
                    this.data.items = response.data.result.data;
                    this.data.loading = false;

                    let number = response.data.result.limit * (response.data.result.page - 1);
                    for (const value of this.data.items) {
                        number = number + 1;
                        value.number = number;
                        value.score = await this.$store.dispatch('numberPrice', value.score);
                        value.group_name = '';
                        if(value.group !== undefined) {
                            value.group_name = value.group.name;
                        }
                    }
                });
            },
            changePosition(id) {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.$store.dispatch('requestAPI', {
                    name: 'MyChurchUserDetail',
                    params: {
                        id: id
                    }
                }).then(response => {
                    this.form.id = id;
                    this.form.position.value = response.data.result.position;
                    this.form.show = true;
                });
            }
        }
    }
</script>