<template>
    <div>
        <div class="subtitle-1 black--text mb-1">
            Total : {{data.total}}
        </div>
        <v-data-table 
            :headers="data.headers" 
            :items="data.items"
            :loading="data.loading"
            :items-per-page="20"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1">

            <template v-slot:no-data>
                No Data
            </template>

            <template v-slot:[`item.price`]="{ item }">
                <span v-if="item.type !== 'Downgrade'">Rp. {{item.price}}</span>
                <span v-else>-</span>
            </template>
        </v-data-table>
        <div class="text-center pa-4" v-if="data.pageTotal > 1">
            <v-pagination
                v-model="data.page"
                :length="data.pageTotal"
                :total-visible="9"
                @input="getData"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['data','getData']
    }
</script>