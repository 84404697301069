<template>
    <v-dialog v-model="form.show" persistent max-width="90%">
        <v-form v-model="form.valid" onSubmit="return false;" ref="form" @submit="formSubmit">
            <v-card>
                <v-card-title>
                    <span class="headline">{{form.action}} Worship</span>
                </v-card-title>
                <v-card-text>
                    <v-text-field 
                        label="Title*"
                        v-model="form.title.value"
                        :rules="form.title.rules"
                        required
                    />
                    <div class="subtitle-1 mb-2">Description</div>
                    <vue-editor class="mb-3" v-model="form.description.value" placeholder="Description" />
                    <v-text-field 
                        label="Speaker*"
                        v-model="form.speaker.value"
                        :rules="form.speaker.rules" 
                        required 
                    />
                    <v-dialog
                        v-model="form.date.show"
                        ref="date"
                        :return-value.sync="form.date.value"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                label="Date*"
                                v-model="form.date.value"
                                :rules="form.date.rules"
                                readonly
                                required
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.date.value" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="form.date.show=false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.date.save(form.date.value)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <v-text-field 
                        label="Time*"
                        v-model="form.time.value"
                        :rules="form.time.rules" 
                        required 
                    />
                    <v-file-input 
                        label="Image"
                        v-model="form.image.value" 
                        accept="image/*"
                    ></v-file-input>
                    <span class="font-italic caption" style="position:relative; top:-15px;">
                        Recommended Size: 375px x 375px
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="form.show = false">Close</v-btn>
                    <v-btn type="submit" color="primary">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
    import { VueEditor } from "vue2-editor";
    import Swal from 'sweetalert2';
    export default {
        props: ['id','form','getData'],
        components: {
            VueEditor
        },
        methods: {
            async formSubmit() {
                if(this.$refs.form.validate()) {
                    let res;
                    let name = 'MyChurchWorshipCreate';
                    let params = {
                        data : {
                            title: this.form.title.value,
                            description: this.form.description.value,
                            speaker: this.form.speaker.value,
                            date: this.form.date.value,
                            time: this.form.time.value,
                            image: this.form.image.value
                        }
                    };
                    if(this.form.action === 'EDIT') {
                        name = 'MyChurchWorshipUpdate';
                        params['id'] = this.form.id;
                    }

                    res = await this.$store.dispatch('requestAPI', {
                        name: name,
                        params: params
                    });

                    if(res !== undefined) {
                        Swal.fire({
                            icon: 'success',
                            html: res.data.message,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            onOpen: () => {
                                Swal.hideLoading();
                            }
                        }).then(() => {
                            this.form.show = false;
                            this.getData();
                        });
                    }
                }
            }   
        }
    }
</script>