<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="6" class="px-3 py-2">
                <div class="font-weight-black">Customer List</div>
            </v-col>
            <v-col cols="12" md="6" class="px-3 py-2 text-right">
                <v-btn class="indigo darken-4 white--text" @click="add">Add Customer</v-btn>
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-card>
            <v-form onSubmit="return false;" @submit="getData">
                <v-card-title>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field v-model="queryString.name" type="text" label="Name"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field v-model="queryString.email" type="text" label="Email"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-btn class="mt-4 indigo darken-4 white--text" type="submit">Filter</v-btn>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-form>
            <v-card-text>
                <div class="subtitle-1 black--text mb-1">
                    Total : {{data.total}}
                </div>
                <Datatable :data="data" :edit="edit" :remove="remove" :getData="getData" />
            </v-card-text>
        </v-card>
        <v-dialog v-model="form.show" persistent max-width="90%">
            <v-form v-model="form.valid" onSubmit="return false;" ref="form" @submit="formSubmit">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{form.action}} Admin</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field 
                            label="Name*"
                            v-model="form.name.value"
                            :rules="form.name.rules"
                            required
                        />
                        <v-text-field 
                            label="Email*"
                            v-model="form.email.value"
                            :rules="form.email.rules" 
                            required 
                        />
                        <v-text-field 
                            label="Phone*"
                            v-model="form.phone.value"
                            :rules="form.phone.rules" 
                            required 
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="form.show = false">Close</v-btn>
                        <v-btn type="submit" color="primary">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>
<script>
    import Swal from 'sweetalert2';
    import Datatable from '@/components/Datatable';
    export default {
        components: {
            Datatable
        },
        data() {
            return {
                queryString: {
                    page: 1,
                    name: '',
                    email: ''
                },
                data: {
                    total: 0,
                    headers: [
                        { text: '#', value: 'number' },
                        { text: 'Name', value: 'name' },
                        { text: 'Email', value: 'email' },
                        { text: 'Phone', value: 'phone' },
                        { text: 'Church', value: 'church' },
                        { text: 'Action', value: 'action' }
                    ],
                    items: [],
                    loading: false
                },
                form: {
                    show: false,
                    action: 'Add',
                    valid: false,
                    id: null,
                    name: {
                        value: '',
                        rules: [
                            v => !!v || 'Name is required',
                        ],
                    },
                    email: {
                        value: '',
                        rules: [
                            v => !!v || 'Email is required',
                            v => /.+@.+/.test(v) || 'Email must be valid',
                        ],
                    },
                    phone: {
                        value: '',
                        rules: [
                            v => !!v || 'Phone is required',
                        ],
                    },
                }
            }
        },
        created: function () {
            this.getData();
        },
        methods: {
            getData() {
                this.data.items = [];
                this.data.loading = true;
                this.data.pageTotal = 0;
                this.$store.dispatch('requestAPI', {
                    name: 'CustomerLists',
                    params: {
                        queryString: this.queryString
                    }
                }).then(response => {
                    this.data.page = parseInt(response.data.result.page);
                    this.data.pageTotal = response.data.result.pageTotal;
                    this.data.total = response.data.result.total;
                    this.data.items = response.data.result.data;
                    this.data.loading = false;

                    let number = response.data.result.limit * (response.data.result.page - 1);
                    for (const value of this.data.items) {
                        number = number + 1;
                        value.number = number;
                    }
                });
            },
            add() {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'ADD';
                this.form.show = true;
            },
            edit(id) {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'EDIT';
                this.$store.dispatch('requestAPI', {
                    name: 'CustomerDetail',
                    params: {
                        id: id
                    }
                }).then(response => {
                    this.form.id = id;
                    this.form.name.value = response.data.result.name;
                    this.form.email.value = response.data.result.email;
                    this.form.phone.value = response.data.result.phone;
                    this.form.show = true;
                });
            },
            async remove(id) {
                Swal.fire({
                    title: 'Are you sure?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.value) {
                        const res = await this.$store.dispatch('requestAPI', {
                            name: 'CustomerDelete',
                            params: {
                                id: id
                            }
                        });
                        
                        Swal.fire({
                            icon: 'success',
                            html: res.data.message,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            onOpen: () => {
                                Swal.hideLoading();
                            }
                        }).then(() => {
                            this.form.show = false;
                            this.getData(this.queryString);
                        });
                    }
                });
            },
            async formSubmit() {
                if(this.$refs.form.validate()) {
                    let res;
                    if(this.form.action === 'ADD') {
                        res = await this.$store.dispatch('requestAPI', {
                            name: 'CustomerCreate',
                            params: {
                                data: {
                                    name: this.form.name.value,
                                    email: this.form.email.value,
                                    phone: this.form.phone.value,
                                }
                            }
                        });
                    } else {
                        res = await this.$store.dispatch('requestAPI', {
                            name: 'CustomerUpdate',
                            params: {
                                id: this.form.id,
                                data: {
                                    name: this.form.name.value,
                                    email: this.form.email.value,
                                    phone: this.form.phone.value,
                                }
                            }
                        });
                    }
                    Swal.fire({
                        icon: 'success',
                        html: res.data.message,
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        onOpen: () => {
                            Swal.hideLoading();
                        }
                    }).then(() => {
                        this.form.show = false;
                        this.getData(this.queryString);
                    });
                }
            }   
        }
    }
</script>