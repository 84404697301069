<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="6" class="px-3 py-2">
                
            </v-col>
            <v-col cols="12" md="6" class="px-3 py-2 text-right">
                <v-btn class="indigo darken-4 white--text" @click="add">Add Membership</v-btn>
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-card>
            <FormFilter :queryString="queryString" :getData="getData" />
            <v-card-text>
                <Datatable :data="data" :getData="getData" />
            </v-card-text>
        </v-card>
        <Form :id="id" :form="form" :getData="getData" :getChurch="getChurch" />
    </div>
</template>
<script>
    import FormFilter from '@/views/Church/Membership/FormFilter';
    import Datatable from '@/views/Church/Membership/Datatable';
    import Form from '@/views/Church/Membership/Form';
    export default {
        components: {
            FormFilter,
            Datatable,
            Form
        },
        props: ['id'],
        data() {
            return {
                queryString: {
                    page: 1,
                    type: {
                        value: '',
                        items: [
                            {
                                id: 'All',
                                name: 'All'
                            },
                            {
                                id: 0,
                                name: 'Subscribe'
                            },
                            {
                                id: 1,
                                name: 'Upgrade'
                            },
                            {
                                id: 2,
                                name: 'Downgrade'
                            },
                            {
                                id: 3,
                                name: 'Extend'
                            }
                        ]
                    },
                    name: '',
                },
                data: {
                    total: 0,
                    headers: [
                        { text: '#', value: 'number' },
                        { text: 'Date', value: 'created_at' },
                        { text: 'Type', value: 'type' },
                        { text: 'Name', value: 'name' },
                        { text: 'Period', value: 'period' },
                        { text: 'Quota', value: 'quota' },
                        { text: 'Price', value: 'price' }
                    ],
                    items: [],
                    loading: false
                },
                form: {
                    show: false,
                    action: 'Add',
                    valid: false,
                    church_id: {
                        value: this.id
                    },
                    membership_id: {
                        value: '',
                        rules: [
                            v => !!v || 'Membership is required',
                        ],
                        items: []
                    }
                }
            }
        },
        created: function () {
            this.getData();
            this.getChurch();
            this.$store.dispatch('requestAPI', {
                name: 'MembershipAll'
            }).then(async response => {
                this.form.membership_id.items = response.data.result;
                for (const value of this.form.membership_id.items) {
                    value.name = value.name+' ('+value.type+')';
                }
            });
        },
        methods: {
            getChurch() {
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchDetail',
                    params: {
                        id: this.id
                    }
                }).then(async response => {
                    let church = response.data.result;
                    church.quota = await this.$store.dispatch('numberPrice', church.quota);
                    church.expired_at = await this.$store.dispatch('dateFormat', church.expired_at);
                    church.bill = await this.$store.dispatch('numberPrice', church.bill);
                    this.$emit("updateData", church);
                });
            },
            getData(page) {
                if(page !== undefined) {
                    this.queryString.page = page;
                }

                this.data.items = [];
                this.data.loading = true;
                this.data.pageTotal = 0;
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchMembershipLists',
                    params: {
                        id: this.id,
                        queryString: this.queryString
                    }
                }).then(async response => {
                    this.data.page = parseInt(response.data.result.page);
                    this.data.pageTotal = response.data.result.pageTotal;
                    this.data.total = response.data.result.total;
                    this.data.items = response.data.result.data;
                    this.data.loading = false;

                    let number = response.data.result.limit * (response.data.result.page - 1);
                    for (const value of this.data.items) {
                        number = number + 1;
                        value.number = number;
                        value.period = await this.$store.dispatch('numberPrice', value.period);
                        value.quota = await this.$store.dispatch('numberPrice', value.quota);
                        value.price = await this.$store.dispatch('numberPrice', value.price);
                    }
                });
            },
            add() {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'ADD';
                this.form.show = true;
            },
        }
    }
</script>