import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login.vue';
import UserArea from '@/views/UserArea.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import Admin from '@/views/Admin.vue';
import Customer from '@/views/Customer.vue';
import Church from '@/views/Church/View.vue';
import ChurchDetail from '@/views/Church/Detail/Detail.vue';
import ChurchMembership from '@/views/Church/Membership/View.vue';
import ChurchAdmin from '@/views/Church/Admin/View.vue';
import Bible from '@/views/Bible.vue';
import Membership from '@/views/Membership/View.vue';

//Admin Church
import MyChurch from '@/views/AdminChurch/Church/View.vue';
import MyMembership from '@/views/AdminChurch/Membership/View.vue';
import MyGroup from '@/views/AdminChurch/Group/View.vue';
import MyUser from '@/views/AdminChurch/User/View.vue';
import MyWorship from '@/views/AdminChurch/Worship/View.vue';
import MyCommunity from '@/views/AdminChurch/Community/View.vue';
import MyEventCategory from '@/views/AdminChurch/EventCategory/View.vue';
import MyEvent from '@/views/AdminChurch/Event/View.vue';
import MyBookCategory from '@/views/AdminChurch/BookCategory/View.vue';
import MyBook from '@/views/AdminChurch/Book/View.vue';
import MyVideoCategory from '@/views/AdminChurch/VideoCategory/View.vue';
import MyVideo from '@/views/AdminChurch/Video/View.vue';
import MyMusicLibrary from '@/views/AdminChurch/MusicLibrary/View.vue';
import MyMusic from '@/views/AdminChurch/Music/View.vue';
import MyArticle from '@/views/AdminChurch/Article/View.vue';
import MyRadio from '@/views/AdminChurch/Radio/View.vue';
import MyBanner from '@/views/AdminChurch/Banner/View.vue';
import MySupport from '@/views/AdminChurch/Support/View.vue';

Vue.use(VueRouter)

const Route = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                title: '',
                requiresGuest: true
            },
        },
        {
            path: '/admin',
            name: 'UserArea',
            component: UserArea,
            meta: {
                title: 'UserArea',
                requiresLogin: true
            },
            children: [
                {
                    path: 'ChangePassword',
                    name: 'Change Password',
                    component: ChangePassword,
                    meta: {
                        title: 'Change Password'
                    },
                },

                //requiresSuperadmin
                {
                    path: 'admin',
                    name: 'Admin',
                    component: Admin,
                    meta: {
                        title: 'Admin',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'customer',
                    name: 'Customer',
                    component: Customer,
                    meta: {
                        title: 'Customer',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'church',
                    name: 'Church',
                    component: Church,
                    meta: {
                        title: 'Church',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'church-detail/:id',
                    name: 'Church Detail',
                    component: ChurchDetail,
                    props: true,
                    meta: {
                        title: 'Church Detail',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'church-membership/:id',
                    name: 'Church Membership',
                    component: ChurchMembership,
                    props: true,
                    meta: {
                        title: 'Church Membership',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'church-admin/:id',
                    name: 'Church Admin',
                    component: ChurchAdmin,
                    props: true,
                    meta: {
                        title: 'Church Admin',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'membership',
                    name: 'Membership',
                    component: Membership,
                    meta: {
                        title: 'Membership',
                        requiresSuperadmin: true
                    },
                },
                {
                    path: 'bible',
                    name: 'Bible',
                    component: Bible,
                    meta: {
                        title: 'Bible',
                        requiresSuperadmin: true
                    },
                },

                //requiresAdminChurch
                {
                    path: 'MyChurch',
                    name: 'MyChurch',
                    component: MyChurch,
                    meta: {
                        title: 'Church',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyMembership',
                    name: 'MyMembership',
                    component: MyMembership,
                    meta: {
                        title: 'MyMembership',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyGroup',
                    name: 'MyGroup',
                    component: MyGroup,
                    meta: {
                        title: 'MyGroup',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyUser',
                    name: 'MyUser',
                    component: MyUser,
                    meta: {
                        title: 'User',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyWorship',
                    name: 'MyWorship',
                    component: MyWorship,
                    meta: {
                        title: 'Worship',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyCommunity',
                    name: 'MyCommunity',
                    component: MyCommunity,
                    meta: {
                        title: 'Community',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyEventCategory',
                    name: 'MyEventCategory',
                    component: MyEventCategory,
                    meta: {
                        title: 'Event Category',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyEvent',
                    name: 'MyEvent',
                    component: MyEvent,
                    meta: {
                        title: 'Event',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyBookCategory',
                    name: 'MyBookCategory',
                    component: MyBookCategory,
                    meta: {
                        title: 'Book Category',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyBook',
                    name: 'MyBook',
                    component: MyBook,
                    meta: {
                        title: 'Book',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyVideoCategory',
                    name: 'MyVideoCategory',
                    component: MyVideoCategory,
                    meta: {
                        title: 'Video Category',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyVideo',
                    name: 'MyVideo',
                    component: MyVideo,
                    meta: {
                        title: 'Video',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyMusicLibrary',
                    name: 'MyMusicLibrary',
                    component: MyMusicLibrary,
                    meta: {
                        title: 'Music Library',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyMusic',
                    name: 'MyMusic',
                    component: MyMusic,
                    meta: {
                        title: 'Music',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyArticle',
                    name: 'MyArticle',
                    component: MyArticle,
                    meta: {
                        title: 'Article',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyRadio',
                    name: 'MyRadio',
                    component: MyRadio,
                    meta: {
                        title: 'Radio',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MyBanner',
                    name: 'MyBanner',
                    component: MyBanner,
                    meta: {
                        title: 'Banner',
                        requiresAdminChurch: true
                    },
                },
                {
                    path: 'MySupport',
                    name: 'MySupport',
                    component: MySupport,
                    meta: {
                        title: 'Support',
                        requiresAdminChurch: true
                    },
                }
            ]
        },
        {
            path: '*',
            redirect: '/'
        },
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

Route.beforeEach((to, _from, next) => {
    if(to.meta.title !== '') {
        document.title = 'Bible Dashboard | '+to.meta.title;
    } else {
        document.title = 'Bible Dashboard';
    }

    if (to.matched.some(record => record.meta.requiresGuest)) {
        if (store.state.user !== '') {
            if (store.state.user.role === 'Superadmin') {
                next({
                    name: 'Admin'
                });
            } else {
                next({
                    name: 'MyChurch'
                });
            }
        }
    }

    if (to.matched.some(record => record.meta.requiresLogin)) {
        if (store.state.user === '') {
            next({
                name: 'Login'
            });
        }
    }

    if (to.matched.some(record => record.meta.requiresSuperadmin)) {
        if (store.state.user === '') {
            next({
                name: 'Login'
            });
        } else {
            if (store.state.user.role !== 'Superadmin') {
                store.commit('logout');
            }
        }
    }

    if (to.matched.some(record => record.meta.requiresAdminChurch)) {
        if (store.state.user === '') {
            next({
                name: 'Login'
            });
        } else {
            if (store.state.user.role !== 'Admin Church') {
                store.commit('logout');
            }
        }
    }

    next();
});

export default Route
