<template>
    <div>
        <v-breadcrumbs
            :items="breadcrumbs"
            large
        ></v-breadcrumbs>
        <v-divider class="mb-4" />
        <v-card v-if="this.church !== null" class="mb-2">
            <v-card-text>
                <v-row no-gutters>
                    <v-col cols="12" md="3">
                        <v-img max-width="100" :src="church.logo" v-if="church.logo !== ''" class="ma-auto"></v-img>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Name</v-list-item-title>
                                <v-list-item-subtitle>{{this.church.name}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Quota</v-list-item-title>
                                <v-list-item-subtitle>{{this.church.user}} / {{this.church.quota}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>Expired</v-list-item-title>
                                <v-list-item-subtitle>{{this.church.expired_at}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-text>
                <v-tabs v-model="tab">
                <v-tab>Info</v-tab>
                <v-tab>Membership</v-tab>
                <v-tab>Admin</v-tab>
                <v-tab>Group</v-tab>
                <v-tab>User</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Info :church="church" :id="id" @updateData="updateData" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Membership :id="id" @updateData="updateData" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Admin :id="id" @updateData="updateData" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <Group :id="id" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card>
                        <v-card-text>
                            <User :id="id" />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import Info from '@/views/Church/Detail/Info';
    import Membership from '@/views/Church/Membership/View';
    import Admin from '@/views/Church/Admin/View';
    import Group from '@/views/Church/Group/View';
    import User from '@/views/Church/User/View';
    export default {
        components: {
            Info,
            Membership,
            Admin,
            Group,
            User
        },
        props: ['id'],
        data() {
            return {
                breadcrumbs: [
                    {
                        text: 'Church List',
                        disabled: false,
                        to: { 
                            name: 'Church'
                        }
                    },
                    {
                        text: 'Church Detail',
                        disabled: true,
                    },
                ],
                tab: 0,
                church: null,
            }
        },
        created: function () {
            this.getData();
        },
        methods: {
            getData() {
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchDetail',
                    params: {
                        id: this.id
                    }
                }).then(async response => {
                    this.church = response.data.result;
                    this.church.quota = await this.$store.dispatch('numberPrice', this.church.quota);
                    this.church.expired_at = await this.$store.dispatch('dateFormat', this.church.expired_at);
                    this.church.bill = await this.$store.dispatch('numberPrice', this.church.bill);
                    this.church.quota = await this.$store.dispatch('numberPrice', this.church.quota);
                });
            },
            updateData(data) {
                this.church = data;
            },
        }
    }
</script>