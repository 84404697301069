<template>
    <div>
        <v-row justify="center" v-if="church !== null">
            <v-col cols="12" md="2" class="px-3 py-2">
                <v-img :src="church.logo" v-if="church.logo !== ''"></v-img>
            </v-col>
            <v-col cols="12" md="10" class="px-3 py-2">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Customer</v-list-item-title>
                        <v-list-item-subtitle>{{church.customer.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Name</v-list-item-title>
                        <v-list-item-subtitle>{{church.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-btn small color="info" title="Edit" class="ml-3 mb-3" @click="edit(id)">
                    Edit
                </v-btn>
                <v-divider />
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>API Key</v-list-item-title>
                        <v-list-item-subtitle>{{church.apikey}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Quota</v-list-item-title>
                        <v-list-item-subtitle>{{church.user}} / {{church.quota}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Expired</v-list-item-title>
                        <v-list-item-subtitle>{{church.expired_at}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>Bill</v-list-item-title>
                        <v-list-item-subtitle>Rp. {{church.bill}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
        </v-row>
        <Form :form="form" :getData="getData" />
    </div>
</template>
<script>
    import Form from '@/views/Church/Form';
    export default {
        props: ['church','id'],
        components: {
            Form
        },
        data() {
            return {
                queryString: {
                    customer: {
                        loading: true,
                        value: 'All',
                        items: [
                            {
                                id: 'All',
                                name: 'All'
                            }
                        ]
                    },
                },
                form: {
                    show: false,
                    action: 'Add',
                    valid: false,
                    id: null,
                    customer_id: {
                        value: '',
                        rules: [
                            v => !!v || 'Customer is required',
                        ],
                        items: []
                    },
                    name: {
                        value: '',
                        rules: [
                            v => !!v || 'Name is required',
                        ],
                    },
                    logo: {
                        value: null,
                    },
                    db: {
                        value: '',
                        rules: [
                            v => !!v || 'DB is required',
                        ]
                    },
                },
            }
        },
        created: function () {
            this.getData();
            this.$store.dispatch('requestAPI', {
                name: 'CustomerAll'
            }).then(async response => {
                this.queryString.customer.items = this.queryString.customer.items.concat(response.data.result);
                this.queryString.customer.loading = false;
                this.form.customer_id.items = response.data.result;
            });
        },
        methods: {
            getData() {
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchDetail',
                    params: {
                        id: this.id
                    }
                }).then(async response => {
                    let church = response.data.result;
                    church.quota = await this.$store.dispatch('numberPrice', church.quota);
                    church.expired_at = await this.$store.dispatch('dateFormat', church.expired_at);
                    church.bill = await this.$store.dispatch('numberPrice', church.bill);
                    church.user = await this.$store.dispatch('numberPrice', church.user);
                    this.$emit("updateData", church);
                });
            },
            edit(id) {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'EDIT';
                this.$store.dispatch('requestAPI', {
                    name: 'ChurchDetail',
                    params: {
                        id: id
                    }
                }).then(response => {
                    this.form.id = id;
                    this.form.customer_id.value = response.data.result.customer.id;
                    this.form.name.value = response.data.result.name;
                    this.form.db.value = response.data.result.db;
                    this.form.show = true;
                });
            },
        }
    }
</script>