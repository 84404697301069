<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="6" class="px-3 py-2">
                <div class="font-weight-black">Change Password Admin</div>
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-card>
            <v-form onSubmit="return false;" @submit="formSubmit" ref="form">
                <v-card-text>
                    <v-text-field label="Old Password" type="password" v-model="form.oldPassword.value" :rules="form.oldPassword.rules"></v-text-field>
                    <v-text-field label="New Password" type="password" v-model="form.newPassword.value" :rules="form.newPassword.rules"></v-text-field>
                    <v-btn type="submit" color="primary">Change Password</v-btn>
                </v-card-text>
            </v-form>
        </v-card>
    </div>
</template>
<script>
    import Swal from 'sweetalert2';
    export default {
        data() {
            return {
                form: {
                    valid: false,
                    oldPassword: {
                        value: '',
                        rules: [
                            v => !!v || 'Old Password is required',
                        ],
                    },
                    newPassword: {
                        value: '',
                        rules: [
                            v => !!v || 'New Password is required',
                        ],
                    },
                }
            }
        },
        methods: {
            async formSubmit() {
                if(this.$refs.form.validate()) {
                    const res = await this.$store.dispatch('requestAPI', {
                        name: 'AdminChangePassword',
                        params: {
                            data: {
                                oldPassword: this.form.oldPassword.value,
                                newPassword: this.form.newPassword.value,
                            }
                        }
                    });
                    Swal.fire({
                        icon: 'success',
                        html: res.data.message,
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        onOpen: () => {
                            Swal.hideLoading();
                        }
                    }).then(() => {
                        this.$refs.form.reset();
                    });
                }
            }   
        }
    }
</script>