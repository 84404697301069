<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" md="6" class="px-3 py-2">
                
            </v-col>
            <v-col cols="12" md="6" class="px-3 py-2 text-right">
                <v-btn class="indigo darken-4 white--text" @click="add">Add Video Category</v-btn>
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-card>
            <FormFilter :queryString="queryString" :getData="getData" />
            <v-card-text>
                <Datatable :data="data" :getData="getData" :edit="edit" :remove="remove" />
            </v-card-text>
        </v-card>
        <Form :form="form" :getData="getData" />
    </div>
</template>
<script>
    import Swal from 'sweetalert2';
    import FormFilter from '@/views/AdminChurch/VideoCategory/FormFilter';
    import Datatable from '@/views/AdminChurch/VideoCategory/Datatable';
    import Form from '@/views/AdminChurch/VideoCategory/Form';
    export default {
        components: {
            FormFilter,
            Datatable,
            Form
        },
        data() {
            return {
                queryString: {
                    page: 1,
                    name: '',
                },
                data: {
                    total: 0,
                    headers: [
                        { text: '#', value: 'number' },
                        { text: 'Name', value: 'name' },
                        { text: 'Action', value: 'action' }
                    ],
                    items: [],
                    loading: false
                },
                form: {
                    show: false,
                    action: 'Add',
                    valid: false,
                    id: null,
                    name: {
                        value: '',
                        rules: [
                            v => !!v || 'Name is required',
                        ],
                    }
                }
            }
        },
        created: function () {
            this.getData();
        },
        methods: {
            getData(page) {
                if(page !== undefined) {
                    this.queryString.page = page;
                }

                this.data.items = [];
                this.data.loading = true;
                this.data.pageTotal = 0;
                this.$store.dispatch('requestAPI', {
                    name: 'MyChurchVideoCategoryLists',
                    params: {
                        queryString: this.queryString
                    }
                }).then(async response => {
                    this.data.page = parseInt(response.data.result.page);
                    this.data.pageTotal = response.data.result.pageTotal;
                    this.data.total = response.data.result.total;
                    this.data.items = response.data.result.data;
                    this.data.loading = false;

                    let number = response.data.result.limit * (response.data.result.page - 1);
                    for (const value of this.data.items) {
                        number = number + 1;
                        value.number = number;
                    }
                });
            },
            add() {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'ADD';
                this.form.show = true;
            },
            edit(id) {
                if(this.$refs.form) {
                    this.$refs.form.reset();
                }
                this.form.action = 'EDIT';
                this.$store.dispatch('requestAPI', {
                    name: 'MyChurchVideoCategoryDetail',
                    params: {
                        id: id
                    }
                }).then(response => {
                    this.form.id = id;
                    this.form.name.value = response.data.result.name;
                    this.form.show = true;
                });
            },
            async remove(id) {
                Swal.fire({
                    title: 'Are you sure?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.value) {
                        const res = await this.$store.dispatch('requestAPI', {
                            name: 'MyChurchVideoCategoryDelete',
                            params: {
                                id: id
                            }
                        });
                        
                        Swal.fire({
                            icon: 'success',
                            html: res.data.message,
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            onOpen: () => {
                                Swal.hideLoading();
                            }
                        }).then(() => {
                            this.form.show = false;
                            this.getData();
                        });
                    }
                });
            },
        }
    }
</script>