<template>
    <v-dialog v-model="ApiKey.show" max-width="90%">
        <v-card>
            <v-card-title class="text-center headline">
                {{ApiKey.value}}
            </v-card-title>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: ['ApiKey']
    }
</script>