var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"subtitle-1 black--text mb-1"},[_vm._v(" Total : "+_vm._s(_vm.data.total)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.data.headers,"items":_vm.data.items,"loading":_vm.data.loading,"items-per-page":20,"hide-default-footer":"","loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","x-small":"","color":"info","title":"Edit"},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-pen")])],1),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"error","title":"Delete"},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fas fa-trash")])],1)],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image !== '')?_c('v-img',{staticClass:"ma-2",attrs:{"width":"100","src":item.image}}):_vm._e()]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":item.file,"target":"_blank","x-small":"","fab":"","color":"info"}},[_c('v-icon',[_vm._v("far fa-file")])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" Date : "+_vm._s(item.date)+" "),_c('br'),_vm._v(" Time : "+_vm._s(item.time)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No Data ")]},proxy:true}],null,true)}),(_vm.data.pageTotal > 1)?_c('div',{staticClass:"text-center pa-4"},[_c('v-pagination',{attrs:{"length":_vm.data.pageTotal,"total-visible":9},on:{"input":_vm.getData},model:{value:(_vm.data.page),callback:function ($$v) {_vm.$set(_vm.data, "page", $$v)},expression:"data.page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }