<template>
    <div>
        <v-data-table 
            :headers="data.headers" 
            :items="data.items"
            :loading="data.loading"
            :items-per-page="20"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1">

            <template v-slot:[`item.church`]="{ item }">
                {{item.church}} <v-btn :to="{ name: 'Church', query: {customer: item.id}}" x-small color="info" title="View Church">View</v-btn>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <div class="my-2">
                    <v-btn fab x-small color="info" title="Edit" class="mr-2" @click="edit(item.id)">
                        <v-icon x-small>fas fa-pen</v-icon>
                    </v-btn>
                    <v-btn fab x-small color="error" title="Delete" @click="remove(item.id)">
                        <v-icon x-small>fas fa-trash</v-icon>
                    </v-btn>
                </div>
            </template>

            <template v-slot:no-data>
                No Data
            </template>
        </v-data-table>
        <div class="text-center pa-4" v-if="data.pageTotal > 1">
            <v-pagination
                v-model="data.page"
                :length="data.pageTotal"
                :total-visible="9"
                @input="getData"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';
    export default {
        props: ['data','edit','remove','getData'],
        methods: {
            fullDate: function(date) { 
                return moment(date).format('DD MMM YYYY');
            },
            fullTime: function(date) { 
                return moment(date).format('HH:mm:ss');
            }
        }
    }
</script>