<template>
    <div>
        <div class="subtitle-1 black--text mb-1">
            Total : {{data.total}}
        </div>
        <v-data-table 
            :headers="data.headers" 
            :items="data.items"
            :loading="data.loading"
            :items-per-page="20"
            hide-default-footer
            loading-text="Loading... Please wait"
            class="elevation-1">

            <template v-slot:[`item.customer`]="{ item }">
                {{item.customer.name}}
            </template>

            <template v-slot:[`item.logo`]="{ item }">
                <v-img width="100" :src="item.logo" v-if="item.logo !== ''" class="ma-2"></v-img>
            </template>
            
            <template v-slot:[`item.bill`]="{ item }">
                Rp. {{item.bill}}
            </template>

            <template v-slot:[`item.quota`]="{ item }">
                {{item.user}} / {{item.quota}}
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <div class="my-2">
                    <!-- <v-btn fab x-small title="View Membership" class="mr-2" :to="{ name: 'Church Membership', params: { id: item.id }}">
                        <v-icon x-small>fas fa-dollar-sign</v-icon>
                    </v-btn>
                    <v-btn fab x-small title="View Admin" class="mr-2" :to="{ name: 'Church Admin', params: { id: item.id }}">
                        <v-icon x-small>fas fa-users-cog</v-icon>
                    </v-btn>
                    <v-btn fab x-small color="primary" title="View ApiKey" class="mr-2" @click="ShowApiKey(item.apikey)">
                        <v-icon x-small>fas fa-key</v-icon>
                    </v-btn> -->
                    <v-btn fab x-small color="primary" title="View Detail" :to="{ name: 'Church Detail', params: { id: item.id }}" class="mr-2">
                        <v-icon x-small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn fab x-small color="info" title="Edit" class="mr-2" @click="edit(item.id)">
                        <v-icon x-small>fas fa-pen</v-icon>
                    </v-btn>
                    <v-btn fab x-small color="error" title="Delete" @click="remove(item.id)">
                        <v-icon x-small>fas fa-trash</v-icon>
                    </v-btn>
                </div>
            </template>

            <template v-slot:no-data>
                No Data
            </template>
        </v-data-table>
        <div class="text-center pa-4" v-if="data.pageTotal > 1">
            <v-pagination
                v-model="data.page"
                :length="data.pageTotal"
                :total-visible="9"
                @input="getData"
            ></v-pagination>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['data','edit','remove','getData','ShowApiKey']
    }
</script>